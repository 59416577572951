import React from "react";

function App() {
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-blue-600 text-white text-center py-8">
        <h1 className="text-4xl font-bold">Your Name</h1>
        <p className="text-xl">Web Developer</p>
      </header>
      <main className="container mx-auto px-4 py-8">
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">About Me</h2>
          <p>
            A passionate web developer with experience in creating responsive
            and user-friendly websites.
          </p>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Experience</h2>
          <div className="mb-4">
            <h3 className="text-xl font-semibold">
              Web Developer at Tech Solutions Inc.
            </h3>
            <p className="text-gray-600">2020 - Present</p>
            <p>
              Developed and maintained client websites using React and Tailwind
              CSS.
            </p>
          </div>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Education</h2>
          <div className="mb-4">
            <h3 className="text-xl font-semibold">
              Bachelor of Science in Computer Science
            </h3>
            <p>University of Technology, 2018</p>
          </div>
        </section>
        <section>
          <h2 className="text-2xl font-bold mb-4">Skills</h2>
          <ul className="list-disc list-inside">
            <li>React</li>
            <li>Tailwind CSS</li>
            <li>JavaScript</li>
            <li>Node.js</li>
            <li>Git</li>
          </ul>
        </section>
      </main>
    </div>
  );
}

export default App;
